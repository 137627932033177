
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import RestTable from '@/components/table/RestTable.vue';
/**
 * 结算价政策
 */
export default defineComponent({
  name: 'ProfitPolicy',
  components: { RestTable },
  setup() {
    const { https, mapper } = useInject();
    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'backerNo',
              label: '渠道',
              type: 'select',
              options: mapper?.device.backerNo
            }
          ]
        },
        remote: {
          action: https?.market.listProfitPolicy
        }
      },
      cols: [
        {
          field: 'bizType',
          label: '业务类型'
        },
        {
          field: 'bizDesc',
          label: '业务描述'
        },
        {
          field: 'backerNo',
          label: '渠道',
          options: mapper?.device.backerNo
        },
        {
          field: 'minRate',
          label: '最低费率'
        },
        {
          field: 'maxRate',
          label: '最高费率'
        },
        {
          field: 'initRate',
          label: '初始费率'
        },
        {
          field: 'minExt',
          label: '最低手续费'
        },
        {
          field: 'maxExt',
          label: '最高手续费'
        },
        {
          field: 'initExt',
          label: '初始手续费'
        },
        {
          field: 'status',
          label: '状态',
          options: mapper?.common.enableStr
        },
        {
          field: 'crtTime',
          label: '创建时间'
        },
        {
          field: 'updTime',
          label: '修改时间'
        },
      ],
      schema: {
        content: {
          cols: [
            {
              field: 'id',
              visible: false,
            },
            {
              field: 'bizType',
              label: '业务类型',
              disabled: ['edit'],
              required: true
            },
            {
              field: 'bizDesc',
              label: '业务描述',
              required: true
            },
            {
              field: 'backerNo',
              label: '渠道',
              type: 'select',
              options: mapper?.device.backerNo,
              required: true
            },
            {
              field: 'minRate',
              label: '最低费率',
              type: 'number',
              scale: 4,
              required: true
            },
            {
              field: 'maxRate',
              label: '最高费率',
              type: 'number',
              scale: 4,
              required: true
            },
            {
              field: 'initRate',
              label: '初始费率',
              type: 'number',
              scale: 4,
              required: true
            },
            {
              field: 'minExt',
              label: '最低手续费',
              type: 'number',
              scale: 2,
              required: true
            },
            {
              field: 'maxExt',
              label: '最高手续费',
              type: 'number',
              scale: 2,
              required: true
            },
            {
              field: 'initExt',
              label: '初始手续费',
              type: 'number',
              scale: 2,
              required: true
            },
            {
              field: 'status',
              label: '状态',
              type: 'switch',
              options: mapper?.common.enableBool,
              required: true
            }
          ]
        }
      },
      builtinCmd: {
        create: { action: https?.market.editProfitPolicy },
        update: { action: https?.market.editProfitPolicy },
      }
    };
    return { tableOpts };
  }
});
